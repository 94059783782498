const webProjectsData = [
  {
    id: 1,
    title: "Konya Ocakbaşı QR Menü",
    image: "img/ocakbasi.png",
    description: "Konyada Bulunan Ocakbaşı İşletmesine Özel QR Menü",
    liveLink: "https://ocakbasi-qr.vercel.app/home",
    githubLink: "https://github.com/janberkaltay/"
  },
  {
    id: 2,
    title: "Portfolyom",
    image: "img/janberkaltay.dev.png",
    description: "",
    liveLink: "https://janberkaltay.dev/",
    githubLink: "https://github.com/janberkaltay/Janberk-Altay-Portfolio"
  },
  {
    id: 3,
    title: "Songül Kınık Portfolyo",
    image: "img/songulknkWeb.png",
    description: "Eğitimci - Sanatçı Songül Kınık İçin Hazırlanan Websitesi",
    githubLink: "https://songulknk.art/",
    liveLink: "https://songulknk.art/"
  },
  {
    id: 4,
    title: "Endless Software Websitesi",
    image: "img/endlessWeb.png",
    description: "Endless Software İçin Hazırlanan Websitesi",
    githubLink: "https://endlesstech.org/",
    liveLink: "https://endlesstech.org/"
  },
  {
    id: 5,
    title: "Güzel Adana Fiber Optik",
    image: "img/GüzelAdanaWeb.png",
    description: "Güzel Adana Fiber Optik İçin Hazırlanan Websitesi",
    githubLink: "https://www.adafon.com.tr/",
    liveLink: "https://www.adafon.com.tr/"
  },

];

export default webProjectsData;
