import React from "react";
import Navbar from "./component/Navbar/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import About from "./component/About/About";
import Projects from "./component/Projects/Projects";
import Home from "./component/Homepage/Home";
import Contact from "./component/Contact/Contact";
import Mobile from "./component/Projects/Mobile";
import WebPage from "./component/Projects/Web";

const App = () => {
    return (
        <>
            <Router>
                <Navbar />
                <Switch >
                    <Route path='/' component={Home} exact> <Home />  </Route>
                    <Route path='/about' component={About} exact> <About /> </Route>
                    <Route path='/projects' component={Projects} exact><Projects /></Route>
                    <Route path='/contact' component={Contact} exact><Contact /></Route>
                    <Route path='/mobile' component={Mobile} exact><Mobile /></Route>
                    <Route path='/web' component={WebPage} exact><WebPage /></Route>
                </Switch>
            </Router>
        </>
    )
}

export default App