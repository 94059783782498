const mobileProjects = [
  {
    id: 1,
    title: "Tarsus Belediyesi Talep Takip Uygulaması",
    image: "https://private-user-images.githubusercontent.com/99503700/367615275-5f23de7f-f9bb-47d0-84af-e928f3979c5b.jpg?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTUiLCJleHAiOjE3NDE1OTMzOTksIm5iZiI6MTc0MTU5MzA5OSwicGF0aCI6Ii85OTUwMzcwMC8zNjc2MTUyNzUtNWYyM2RlN2YtZjliYi00N2QwLTg0YWYtZTkyOGYzOTc5YzViLmpwZz9YLUFtei1BbGdvcml0aG09QVdTNC1ITUFDLVNIQTI1NiZYLUFtei1DcmVkZW50aWFsPUFLSUFWQ09EWUxTQTUzUFFLNFpBJTJGMjAyNTAzMTAlMkZ1cy1lYXN0LTElMkZzMyUyRmF3czRfcmVxdWVzdCZYLUFtei1EYXRlPTIwMjUwMzEwVDA3NTEzOVomWC1BbXotRXhwaXJlcz0zMDAmWC1BbXotU2lnbmF0dXJlPTZhZmUyM2ZiMzJhMjI4YzE1YTUwMjQ3YTk1ZDE4ZGY3NTA0ZjBkMTE1MWJiZGUwM2JiNzgyYzhkZDVhNWFkYTImWC1BbXotU2lnbmVkSGVhZGVycz1ob3N0In0.7ZytlBCKJeTi36E3e6GsfIhyH8DzTHT0ovH4djlRhI0",
    description: "Tarsus İlçe Belediyesine Gelen Taleplerin Kurum İçin Takibini Sağlayan Mobil Uygulama",
    githubLink: "https://github.com/janberkaltay/"
  },
  {
    id: 1,
    title: "Hayvan Barınaklarındaki Hayvanların Sahiplendirilmesi",
    image: "https://private-user-images.githubusercontent.com/99503700/367615003-8ae062f9-2e58-4398-96c9-e3f3aeb79ae0.jpg?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTUiLCJleHAiOjE3NDIxOTQ5ODMsIm5iZiI6MTc0MjE5NDY4MywicGF0aCI6Ii85OTUwMzcwMC8zNjc2MTUwMDMtOGFlMDYyZjktMmU1OC00Mzk4LTk2YzktZTNmM2FlYjc5YWUwLmpwZz9YLUFtei1BbGdvcml0aG09QVdTNC1ITUFDLVNIQTI1NiZYLUFtei1DcmVkZW50aWFsPUFLSUFWQ09EWUxTQTUzUFFLNFpBJTJGMjAyNTAzMTclMkZ1cy1lYXN0LTElMkZzMyUyRmF3czRfcmVxdWVzdCZYLUFtei1EYXRlPTIwMjUwMzE3VDA2NTgwM1omWC1BbXotRXhwaXJlcz0zMDAmWC1BbXotU2lnbmF0dXJlPTg0NDFlYWM1MDg2MmUyMThlYzhmZWE3NmUwOTBiMjNkZDlkMDQzNWFiZjIxZDdmY2NhNTEzMGYwZTdlMWM2MzkmWC1BbXotU2lnbmVkSGVhZGVycz1ob3N0In0.P9M8h2YBUJAHLX1Z6YD6J3neIyRjqnbs3pPlgsfo_yo",
    description: "TÜBİTAK 2209-A Projem Kapsamında Geliştirdiğim Uygulama",
    githubLink: "https://github.com/janberkaltay/"
  },
  {
    id: 1,
    title: "Can Akademi  -  Can Mobil",
    image: "https://github.com/janberkaltay/Flutter-Uplod-and-View-Pdf-From-Firebase/blob/main/Screenshot_20230721_144711.png?raw=true",
    description: "Adana'da bulunan Can Akademi'ye özel",
    githubLink: "https://play.google.com/store/apps/details?id=com.canmobil.can_akademi"
  },
  {
    id: 2,
    title: "QR Kod Tarayıcı",
    image: "https://play-lh.googleusercontent.com/07C5CsxnycZaUQkuFkRvYnGvw2AL6CQeIh22sGBhgDHEPbr7ziRS50jr_04UfxCdY1KL=w2560-h1440-rw",
    description: "QR Kod Tarayıcı",
    githubLink: "https://play.google.com/store/apps/details?id=com.endlesstech.qr_tarayici"
  },
  {
    id: 3,
    title: "Flutter Adana Kent Full AppClone",
    image: "https://user-images.githubusercontent.com/99503700/228924109-967bd66a-ae40-465d-a18b-22d335b4f2b2.png",
    description: "-",
    githubLink: "https://github.com/janberkaltay/Flutter-Adana-Akilli-Kent-Full-AppClone"
  },
  {
    id: 4,
    title: "Flutter Basic Link App",
    image: "https://user-images.githubusercontent.com/99503700/237192416-697eb204-ea4a-4e05-9628-2bf9743fa089.png",
    description: "-",
    githubLink: "https://github.com/janberkaltay/Flutter-Basic-Link-App"
  },
  {
    id: 5,
    title: "Flutter Dinamik Karşılama Ekranı",
    image: "https://user-images.githubusercontent.com/99503700/230498290-26bdf401-171c-4552-83cd-1f7808c2df66.png",
    description: "Dinamik bir karşılama ekranı",
    githubLink: "https://github.com/janberkaltay/Flutter-Dynamic-Onboarding-Screens"
  },
  {
    id: 6,
    title: "Flutter Gizli Menü",
    image: "https://github.com/janberkaltay/Flutter-Hidden-Drawer-Menu/blob/master/Screenshot_20230314_124943.png?raw=true",
    description: "-",
    githubLink: "https://github.com/janberkaltay/Flutter-Hidden-Drawer-Menu"
  },
  {
    id: 7,
    title: "Onboarding Screens Clone",
    image: "https://github.com/janberkaltay/akilli_kent_Onboarding_screen_clone/blob/master/screenshots/%C4%B1ntro_page_1.png?raw=true",
    description: "-",
    githubLink: "https://github.com/janberkaltay/akilli_kent_Onboarding_screen_clone"
  },
  {
    id: 8,
    title: "Flutter Line Graphics App",
    image: "https://user-images.githubusercontent.com/99503700/260309872-6ed2b654-f15f-47ff-9c12-868cd42a87c2.png",
    description: "-",
    githubLink: "https://github.com/janberkaltay/flutter-graphics"
  },

];

export default mobileProjects;
